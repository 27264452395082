import React from 'react'
import styles from './table-of-contents.scss'
import { createTableOfContentsStructureFromContentful, handleInnerNavigation } from "../../helpers.js"
import { IoIosExpand, IoIosContract } from "react-icons/io";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

class TableOfContents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.defaultOpen === false ? false : true,
      offset: 0
    }

    this.nodesTree = this.props.nodesTree || createTableOfContentsStructureFromContentful(this.props.nodes)
    this.theme = this.props.theme
    this.titleTableOfContents = this.props.titleTableOfContents

    this.toggleContent = this.toggleContent.bind(this)
  }
  
  /**
   * Updates offset for moving to anchor
   * @param {int} offset
   */
  updateOffset(offset) {
    this.setState({
      offset: offset
    })
  }
  
  /**
   * Shows/Hide list of contents
   * @return {void}
   */
  toggleContent(e) {
    this.setState({
      open: !this.state.open
    })
  }
  
  /**
   * Renders ToC tree recursively
   * @param  {Array} nodes
   * @return {Node}
   */
  renderTree(nodes) {
    return (
      <ol>
        { nodes.map((node, index) => {
          return (
            <li key={index}>
              <a href={`#h-${node.id}`} onClick={(e) => handleInnerNavigation(e, this.state.offset)}>{node.label}</a>
              {
                node.children && node.children.length ? this.renderTree(node.children) : ''
              }
            </li>
          )
        }) }
      </ol>
    )
  }
  
  render() {    
    return (
      <div className={`toc ${this.props.className ? this.props.className : ''}`}>
        <div className="d-flex align-items-center">
          <h2 className="toc-title m-0">{this.titleTableOfContents ? this.titleTableOfContents : `Table of Contents`}</h2>
          <button className="btn btn-square toc-toggler ml-2" onClick={this.toggleContent} title="Toggle Table of Contents">
            { this.state.open ? (
              <FiChevronUp title="Close Table of Contents"/>
            ) : (
              <FiChevronDown title="Close Table of Contents"/>
            ) }
          </button>
        </div>
        <div className={`toc-content ${this.state.open ? '' : 'closed'}`}>
          { this.nodesTree ? this.renderTree(this.nodesTree) : ''}
        </div>
      </div>
    )
  }
}

export default TableOfContents

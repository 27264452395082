import React from 'react'
import {graphql, Link} from 'gatsby'
import { get, capitalize } from 'lodash'
import BaseModal from '../components/modals/base-modal'
import Layout from '../components/theme/layout'
import Header from '../components/theme/header'
import TableOfContents from '../components/table-of-contents/table-of-contents'
import SEO from '../components/seo'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import defaultRTRenderOptions from "../../contentful/rich-text-renderer-options"
import { postUrl, blogReviewsListUrl, parseContentfulShopifyProduct, getHeaderSize, buildBreadcrumbsItem, authorUrl, collectionUrl } from "../helpers.js"
import AddThis  from '../components/add-this/add-this'
import Author  from '../components/contentful/author'
import styles from '../scss/post.scss'
import loadable from '@loadable/component'

const Badge = loadable(() => (import('react-bootstrap/Badge')))
const KlaviyoForm = loadable(() => (import('../components/klaviyo-form/klaviyo-form')))
const RelatedProducts = loadable(() => (import('../components/related-products/related-products')))
const DiscountBox = loadable(() => (import('../components/discount-box/discount-box')))
const EmbedVideo = loadable(() => (import('../components/wistia/embed-video')))
const DosageCalculator = loadable(() => (import('../components/dosage-calculator/dosage-calculator')))
const PostCard = loadable(() => (import('../components/contentful/post-card')))


class BlogReviewTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.post = get(this.props, 'data.contentfulBlogReview')
    this.breadcrumbs = [
      buildBreadcrumbsItem('Happy Tails', blogReviewsListUrl(this.post.category)),
      buildBreadcrumbsItem(this.post.title, this.props.location.href)
    ]

    this.publisherName = get(this, 'props.data.site.siteMetadata.publisher.name', '') || ''

    this.seo = {
      description: this.post.metaDescription,
      title: this.post.seoTitle,
      meta: {
        title: this.post.seoTitle,
        image: get(this.post, 'heroImage.fixed'),
        ogMeta: {
          'og:site_name': this.publisherName,
          'og:type': 'article',
          'article:author': this.post.author.name,
          'article:published_time': this.post.formattedCreatedDate,
          'article:modified_time': this.post.formattedUpdatedDate
        }
      },
      children: [],

      disallowIndexing: this.post.disallowIndexing || true
    }
    this.url = postUrl(this.post, this.props.data.site.siteMetadata.siteUrl)
    this.relatedProducts = get(this.post, 'relatedProducts', null)
    this.defaultImage = get(this.props, 'data.defaultImage.childImageSharp.fluid')

    this.discountBoxTitle = "Try Honest Paws CBD Oil Today!"
    this.discountBoxTestimonial = ["“Honest paws has done wonders for my old girl. her back legs are not shaking as much and she wants to play. I have tried many products but this one has done what the others did not.” - ", <b>Kim B.</b>]
    this.discountBoxCTAText = "Get 15% Off CBD Oil for Dogs Use Promo Code: TRYCBD"
    this.discountBoxCTAUrl = "/products/relief-tincture/"
    this.discountBoxMessage = [<b>100% Satisfaction Guarantee.</b>]

    this.klaviyoFormId = "klaviyo-form-XBG29B"

    this.modalShare = null
    this.displayShareOptions = this.displayShareOptions.bind(this)
  }

  componentDidMount() {

    if (this.tableOfContentsRef) {
      const headerSize = getHeaderSize()
      this.tableOfContentsRef.updateOffset(0 - headerSize.height)
    }

  }

  /**
   * Displays share options inside a modal
   * @param  {event} e
   * @return {void}
   */
  displayShareOptions(e) {
    this.modalShare.showModal()
  }


  render() {
    const options = {
      ...defaultRTRenderOptions
    }

    let headerOptions = {
      location: this.props.location
    }

    const header = (<Header { ...headerOptions } />)

    if (this.post.canonicalUrl) {
      this.seo.children.push(<link rel="canonical" href={ this.post.canonicalUrl } key='canonical-url'  />)
    }
    if (this.post.schemaOrgJson) {
      this.seo.children.push(<script type="application/ld+json" key='structured-data'>{ this.post.schemaOrgJson.internal.content }</script>)
    }
    return (
      <Layout containerClassName={`post ${this.post.slug} post-${this.post.contentful_id} breadcrumbs-ccc`}
              location={ this.props.location } breadcrumbs={ this.breadcrumbs } header={header}>
        <SEO { ...this.seo }></SEO>
        <div className="bg-primary text-center blog-post-title">
          <div className="col-12 col-lg-6 offset-lg-3 pt-lg-6 pb-lg-4 py-0">
            <h1 itemProp="headline name mt-5">{ this.post.title }</h1>
          </div>
          <div className="blog-data">
            Written By: <a href={authorUrl(this.post.author)} rel="author" target="_blank" className="blog-author">{this.post.author.name} {this.post.author.title}</a>
            <meta itemProp="datePublished" content={ this.post.formattedCreatedDate } />
            <div itemProp="dateModified">{ this.post.formattedUpdatedDate }</div>
          </div>
        </div>
        <div className="container new-blog-style">
          <article className="post-content-container blog-reviews" itemScope itemType="https://schema.org/BlogPosting">
            <meta itemProp="url" content={ this.url } />
            <link itemProp="mainEntityOfPage" href={ this.url } />
            <meta itemProp="datePublished" content={ this.post.formattedCreatedDate } />
            <meta itemProp="dateModified" content={ this.post.formattedUpdatedDate } />
            <meta itemProp="headline" content={ this.post.title } />
            { this.post.author ? (
              <div itemProp="author" itemScope itemType="https://schema.org/Person">
                <meta itemProp="name" content={ this.post.author.name } />
                <meta itemProp="honorificSuffix" content={ this.post.author.title } />
              </div>
            ) : '' }
            { this.publisherName ? (
              <link itemProp="publisher" href={ `#${this.publisherName.replace(' ', '')}Org` } />
            ) : ''}

            {/*<div className="text-center d-md-none">*/}
            {/*<button onClick={this.displayShareOptions} className="btn btn-success text-white">SHARE</button>*/}
            {/*</div>*/}
            {this.post.summary ? (
              <div className="post-summary d-none" itemProp="description">
                { documentToReactComponents(this.post.summary.json, options) }
              </div>
            ) : '' }
            <meta itemProp="image" content={ this.post.heroImage ? this.post.heroImage.fixed.src : this.defaultImage.src } />
            { this.post.featuredVideo ? (
              <>
                <EmbedVideo hashedId={this.post.featuredVideo.hashed_id} className="hero-container d-md-none" />
              </>
            ) : ( this.post.heroImage ? "" : "")}
            {
              this.post.showTableOfContents ? (
                <TableOfContents nodes={this.post.body.json.content} className='bg-light d-md-none' ref={(ref) => { this.tableOfContentsRef = ref} } />
              ) : ''
            }
            <div className="row">
              <div className="col-12 col-md-3 d-none d-md-block">
                {/*<div className="text-center mb-3">*/}
                {/*<button onClick={this.displayShareOptions} className="btn btn-success text-white mt-3">SHARE</button>*/}
                {/*</div>*/}
                {
                  this.post.showTableOfContents ? (
                    <TableOfContents nodes={this.post.body.json.content} className='bg-transparent d-none d-md-block theme-blog-post pt-0 mt-2' ref={(ref) => { this.tableOfContentsRef = ref} } />
                  ) : ''
                }
              </div>
              <div className="col-12 col-md-6">
                <div className="post-content" itemProp="articleBody">
                  { this.post.featuredVideo && (
                    <>
                      <EmbedVideo hashedId={this.post.featuredVideo.hashed_id} className="d-none d-md-block mb-4" />
                    </>
                  )}
                  {
                    this.post.showDosageCalculator ? (
                      <DosageCalculator fullWidth={true}/>
                    ) : ''
                  }
                  { documentToReactComponents(this.post.body.json, options) }
                  <div className="mt-4 mb-2 text-center">
                    <button onClick={this.displayShareOptions} className="btn btn-success text-white">SHARE</button>
                  </div>
                  {/*<Author author={this.post.author} nameAsLink={ true }/>*/}
                </div>
              </div>
              <div className="col-12 col-md-3 d-none d-md-block about-hp-description">
                <div><strong>About Honest Paws</strong></div>
                <div className="mt-2 mision-text">
                  Our mission is to provide premium quality CBD products that help support the health and happiness of
                  the animals we call family. <br/>Discover our collection of CBD products today, including <Link to={collectionUrl('cbd-oil-for-dogs')}>CBD Oil for
                  Dogs</Link>, <Link to={collectionUrl('cbd-dog-treats')}>CBD Dog Treats</Link>, and <Link to={collectionUrl('cbd-for-cats')}>CBD for Cats.</Link>
                </div>
                { this.post.tags && (
                  <>
                    <div className="mt-4 mb-2"><strong>Tags</strong></div>
                    <div className="my-2">
                      { this.post.tags.map((tag, i) => (
                        <Badge key={i} variant="gray" className="tag">{tag}</Badge>
                      )) }
                    </div>
                  </>
                )}
                { this.post.relatedPosts ? (
                  <div className="mt-3 container row">
                    <div className="mb-3"><strong>You might also like</strong></div>
                    {this.post.relatedPosts.map(( post, index ) => {
                      return <Link to={postUrl(post)} className="col-12 p-0 mb-2" key={index}>{post.title}</Link>
                    })}
                  </div>
                ) : '' }

                { this.post.showDiscountBox && (
                  <DiscountBox title={this.discountBoxTitle} testimonial={this.discountBoxTestimonial} ctaText={this.discountBoxCTAText} ctaUrl={this.discountBoxCTAUrl} message={this.discountBoxMessage}/>
                ) }
              </div>
              { this.post.tags && (
                <div className="col-12 d-md-none">
                  <div className="mt-4 mb-2"><strong>Tags</strong></div>
                  <div className="my-2">
                    { this.post.tags.map((tag, i) => (
                      <Badge key={i} variant="gray" className="tag">{tag}</Badge>
                    )) }
                  </div>
                </div>
              )}
            </div>
          </article>
        </div>
        { this.relatedProducts ? (
          <RelatedProducts className='bg-yellow-light'
                           products={this.relatedProducts.map(parseContentfulShopifyProduct)} readMoreLink={true} useImageSrc={true} useBlockButton={false} title={`Discover Our Popular <br class='d-md-none' />Bites & Oils`} />
        ) : ''}
        { this.post.relatedPosts ? (
          <section className="related-posts bg-light d-md-none">
            <div className="container">
              <h2 className="text-center mt-0 mb-5 text-primary">Read More About {this.post.category === 'cbd' ? 'CBD' : capitalize(this.post.category)}</h2>
              <div className="posts-cards d-flex justify-content-center row">
                {this.post.relatedPosts.map(( post, index ) => {
                  return <PostCard title={post.title} url={postUrl(post)} image={post.heroImage} className="col-12 col-sm-6 col-md-4 col-xl-3 mb-5 mb-md-0" key={index}/>
                })}
              </div>
            </div>
          </section>
        ) : '' }
        <BaseModal ref={(ref) => this.modalShare = ref} title='Share options!' size='md'>
          <AddThis />
        </BaseModal>
      </Layout>
    )
  }
}

export default BlogReviewTemplate

export const pageQuery = graphql`
  query reviewBySlug($slug: String!) {
    site {
      siteMetadata {
        publisher {
          name
        }
        title, 
        siteUrl
      }
    }
    contentfulBlogReview(slug: { eq: $slug }) {
      ...contentfulBlogReview
    }
    defaultImage: file(relativePath: { eq: "og-default-3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Helmet from 'react-helmet'

class AddThis extends React.Component {
  constructor(props) {
    super(props)

    this.addThisPublicId = this.props.data.site.siteMetadata.addThisPublicId
    this.classIdentifier = 'addthis_toolbox'
    this.scriptId = 'addthis-script'
  }
  
  componentDidMount() {
    if (window.addthis) {
      //Means the script was already added
      addthis.toolbox(`.${this.classIdentifier}`);
    } else {
      if (!document.getElementById(this.scriptId)) {
        // Means it wasn't added
        const script = document.createElement('script')
        script.src = `//s7.addthis.com/js/300/addthis_widget.js#pubid=${this.addThisPublicId}`
        script.setAttribute('id', this.scriptId)
        script.setAttribute('defer', true)
        document.head.append(script)
      }
    }
  }

  render() {
    return this.props.children ? (
      <div className={`${this.classIdentifier} ${this.props.className || ''}`}>
        { this.props.children }
      </div>
    ) : (
      <div className="addthis_inline_share_toolbox"></div>
    )
  }
}

export default (props) => {
  const data = useStaticQuery(graphql`
  query AddThisQuery {
    site {
      siteMetadata {
        addThisPublicId
      }
    }
  }
`)

  return (
    <AddThis {...props} data={data}/>
  )
}
